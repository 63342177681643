import React from "react";
import AdSense from "react-adsense";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Navbar,
  Nav,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import "../../styles/LandingPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faCarBurst } from "@fortawesome/free-solid-svg-icons";
import { faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

export default function LandingPage() {
  let navigate = useNavigate();
  const handlePick = (x) => {
    navigate(x);
  };
  return (
    <div className="landing-background-div">
      <Row className="landing-top-text-row">iRacing stats,&nbsp;</Row>
      <Row className="landing-green-wall">
        {/* SELECTION OPTIONS */}
        <Container className="container1234">
          <Row
            className="landing-option-text-row"
            onClick={() => handlePick("iratingstats")}
          >
            <FontAwesomeIcon icon={faClipboard} size="2x" />
            <p className="landing-selection-text">iRating Stats</p>
          </Row>
          <Row
            className="landing-option-text-row"
            onClick={() => handlePick("incidentstats")}
          >
            <FontAwesomeIcon icon={faCarBurst} size="2x" />
            <p className="landing-selection-text">Incident Stats</p>
          </Row>
          <Row
            className="landing-option-text-row"
            onClick={() => handlePick("searchmember")}
          >
            <FontAwesomeIcon icon={faUsersLine} size="2x" />
            <p className="landing-selection-text">Member Stats</p>
          </Row>
          <Row
            className="landing-option-text-row"
            onClick={() => handlePick("about")}
          >
            <FontAwesomeIcon icon={faPaperPlane} size="2x" />
            <p className="landing-selection-text">About</p>
          </Row>
        </Container>
        <Row className="landing-background-accent1"></Row>
        <Row className="landing-background-accent2"></Row>
      </Row>
    </div>
  );
}
